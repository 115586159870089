@import "./globals.scss";

.myTable {

    @include mediaQuery(992px) {
        .cardType .ant-table-cell:has(.invoiceTableFullName) {
            display: none;
        }
    
        .cardType .ant-table-cell:has(.invoiceTableIndex) {
            display: none;
        }
    
        .cardType .ant-table-cell:has(.operationInvoiceDesktop) {
            display: none;
        }
    
        .invoiceTableTitle {
            @include font(var(--light-blue2), 1rem, 500, 25px);
        }
    }

    .ant-table-cell::before {
        display: none;
    }

    .ant-table-cell {
        border-bottom: none !important;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        display: none;
    }

    .ant-pagination-item {
        background-color: var(--dark-blue);
        color: var(--white);
    }

    .ant-pagination-item-active {
        background: var(--light-blue6);
        border: none;
        color: var(--white);
    }
}

.myTable th {
    text-wrap: nowrap;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 21px;
    color: var(--light-blue9) !important;
}

.myTable td {
    color: var(--white) !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.myTable .ant-table-cell {
    background-color: transparent !important;
}


.myTable .ant-table {
    background-color: transparent !important;
}

.myTable .ant-table-row:nth-child(odd) {
    background-color: var(--dark-blue);
}

.notAnyDataText{ 
    @include font(var(--white), 1.25rem, 400, 30px);
    margin-bottom: 2rem;
}

@media (max-width: 992px) {
    .myTable .ant-table-content{
        overflow: auto;
    }

    .myTable.cardType thead {
        display: none;
    }
    .myTable.cardType th {
        display: none;
    }

    .myTable.cardType td, .myTable.cardType th {
        display: block;
    }

    .myTable.cardType .ant-table-row {
        background-color: var(--dark-blue2) !important;
        border-radius: 10px;
        margin-bottom: 2.5rem;
        display: block;
    }

    .myTable.cardType td {
        color: var(--light-blue2) !important;
        border: none !important;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
    }
}



.paginationHolder {
    justify-content: end;
}

@include mediaQuery(992px) {
    .paginationHolder {
        justify-content: center;
    }   
}

.takePagination {

    justify-content: end;

    .childrenLabelBox {
        width: 150px;
        padding: 0;
        padding-top: 5px;
        padding-left: 10px;
    }

    // .dropdown-menu {
    //     background: var(--light-blue3);
    // }

    // .dropdown-toggle::after {
    //     display: none;
    // }

    // button {
    //     color: var(--white) !important;
    //     margin: 0 1rem;
    //     border: 1px solid var(--white) !important;
    //     padding: 0.25rem 1.5rem;
    //     border-radius: 5px;
    //     cursor: pointer;
    //     background-color: transparent !important;
    // }

    a {
        text-align: center;
        margin-bottom: 0.5rem;

        &:active, &:focus, &:hover {
            background-color: transparent;
            color: var(--dark-blue2);
        }


    }
}

@include mediaQuery(992px) {
    .takePagination {
        justify-content: center;
    }
}

.paginationLayOut {
    align-items: center;

    .btn{
        background: none;
    border: none;
    }
}

.afterBeforePagination {
    border: none;
    background-color: var(--light-blue3);
    border-radius: 5px;
}

.currentPagePagination {
    .childrenLabelBox {
        width: 75px;
        height: 38px;
        padding-top: 3px;
        padding-left: 8px;
    }

    a {
        text-align: center;
    }
}

.borderTypeOfProject {
    .dropdown-menu {
        width: 100%;
        background-color: var(--dark-blue6);
        text-align: right;
        max-height: 200px;
        overflow: auto;
        
        a {
            color: var(--white);
            transition: 0.5s;

            &:hover {
                background-color: var(--dark-blue13);
            }
        }
    }
}