@import url("../fonts/css/fontiran.css");

* {
    font-family: iranyekan;
}

.ant-steps-item-icon {
    position: relative;
}

.ant-steps-icon {
    position: absolute !important;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ant-steps-item-title {
    color: #ffffffa9 !important;
}

.ant-steps-item.ant-steps-item-process {
    .ant-steps-item-title {
        color: #fff !important;
    }
}

.ant-steps-item-disabled {
    .ant-steps-item-icon {
        background-color: #ffffff8a !important;
    }
}



.basicInfoShop .customFloatInput {
    height: 100%;

    textarea {
        height: 100%;
    }
}

.addBtnHolderShot {
    display: flex;
    justify-content: center;

    .addBtnShot {
        width: 200px;
    }
}

.ant-select-selector input {
    color: #Fff !important;
}

.create_shot_step {
    .ant-steps-item-wait {
        .ant-steps-item-icon {
            border-color: var(--main-blue);

            .ant-steps-icon {
                color: var(--main-blue);
            }
        }
    }

    .ant-steps-item {

        &::after {
            display: none !important;
        }
    }
}

.imgTable {
    width: 100px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.border-bottom-live{
    border-bottom: solid 1px var(--main-gray);
}