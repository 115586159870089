@import "./globals.scss";

.headerBox {
    display: flex;
    justify-content: space-between;
}

.header {
    background-color: var(--dark-blue3);
    color: var(--white);
    padding: 1.12rem 0;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
}

.ltr .header {
    left: auto;
    right: 0;
}

.iconColors {
    color: var(--light-blue);
}

.profilePic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.header_projectPic {
    width: 40px;
    height: 40px;
    position: relative;
    margin-left: 28px;
}   

@include mediaQuery(992px) {
    .header_projectPic {
        margin-right: 1rem;
    }   
}

.ltr .header_projectPic {
    margin-right: 28px;
    margin-left: 0;
}   

@include mediaQuery(992px) {
    .ltr .header_projectPic {
        margin-left: 1rem;
        margin-right: 1rem;
    }   
}

.header_projectPic img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50% -50%;
    border-radius: 8px;
    object-fit: cover;
}

.header_projectTitle {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 32px;
    color: var(--white);
}

.header_projectList, .header_addProject {
    font-weight: 600;
    font-size: 1rem;
    line-height: 25px;
    color: var(--main-blue);
    background: var(--dark-blue);
    opacity: 0.79;
    padding: 9.5px 1rem;
    border-radius: 12px;
    cursor: pointer;
}

@include mediaQuery(380px) {
    .header_projectTitle {
        font-size: 1rem;
    }

    .header_projectList, .header_addProject {
        font-size: 0.85rem;
        padding: 5.5px 0.75rem;
    }
}

.header_addProject {
    display: flex;
}

.header_addProject_icon {
    display: inline-block;
    padding-top: 2px;
}

.projectList_item {
    background: var(--dark-blue9);
    border-radius: 10px;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    margin: 1rem 0;
    cursor: pointer;
}

.projectList_item-active {
    background: var(--main-blue);
    cursor: default;
}

.projectList_picture {
    width: 41px;
    height: 41px;
    position: relative;
    margin-left: 1.75rem;
}

.ltr .projectList_picture {
    margin-right: 1rem;
    margin-left: 0.5rem;
}

.projectList_picture img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    margin: -50% -50%;
    object-fit: cover;
    border-radius: 8px;
}

.projectList_title {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: var(--white);
}

.modal-content:has(.changeProject) .modal-header {
    border: none;
}

.changeProject {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

    .currentProject, .selectedProject {
        position: relative;
        width: 70px;
        height: 70px;
        border-radius: 50%;

        &::before {
            content: "";
            width: 105%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -50% -50%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -50% -50%;
            border-radius: 50%;
            border: 5px solid var(--white);
        }
    }

    .currentProject::before {
        // background-image: url("../../img/yellowBackProject.svg");
        background-repeat: no-repeat;
        background-size: contain;
    }

    .selectedProject::before {
        // background-image: url(../../img/blueBackProject.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.addProjectSubmitBtnInModal {
    background: var(--main-blue);
    opacity: 0.79;
    border-radius: 12px !important;
    padding: 9.5px 1rem !important;
}

.switchProjectTxt {
    @include font(var(--white), 1.25rem, 500, 31px);
    width: 100%;
    margin-top: 30px;
    text-align: center;
    margin-bottom: 1rem;
}

.fromProject {
    @include font(var(--main-orange2), 1.125rem, 500, 31px);
}

.to_project {
    @include font(var(--white), 1.25rem, 500, 31px);
    margin: 0 1rem
}

.toProject {
    @include font(var(--main-blue), 1.125rem, 500, 31px);
}

.switchProjectBtn {
    padding: 1rem 32.5px;
    border-radius: 12px;
    @include font(var(--white), 1rem, 600, 25px);
}

.submitSwitchProject {
    background: var(--main-blue);
    opacity: 0.79;
}

.declineSwitchProject {
    background: var(--dark-blue6);
    opacity: 0.79;
    margin-right: 8px;
}
