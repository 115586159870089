@import "./globals.scss";

.customDatePickerCalendar {
    --gray: var(--light-blue);
    --backgroundGray: var(--dark-blue6);

    z-index: 9;
    background: var(--backgroundGray) !important;
    padding-top: 16px !important;

    .days-titles {
        div {
            color: var(--gray) !important;
        }
    }

    .JC-days {
        margin-top: 12px;
        background: var(--backgroundGray) !important;

        .day-items {
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
        }
    
        .day-items:hover, .day-items.selected {
            background: var(--main-blue) !important;
            border-radius: 100px;
        }
        .day-items:hover{
            background: var(--white) !important;
        }
    }

    .JDheader {
        font-size: 20px;
        height: 0px;
        .right {
            span{
                display: none ;
            }
            .number {
                display: block;
                position: absolute;
                color: var(--gray);
                top: 20px;
                right: 9%;
            }
        }
    }

    .JC-months::before{
        content: '';
        display: flex;
        width: 70%;
        height: 31px;
        position: absolute;
        z-index: 1;
    }

    .JC-months {
        font-size: 20px;

        .holder {
            width: 100% !important;
            position: relative;

            div {
                color: var(--light-blue) !important;
            }

            .print-month{
                text-align: right;
                margin-right: 12px;
            }
            .prev {
                position: absolute;
                left: 28px;
                width: 30px;
            }
            .next{
                position: absolute;
                left: 6px;
                width: 30px;
            }
        }
    }
}

.__datepicker-dropdown{
    width: 300px !important;
    --gray: var(--light-blue);
    --backgroundGray: var(--dark-blue6);

    background: var(--backgroundGray) !important;

    .__datepicker-weak{
        background-color: unset !important;

        .__datepicker-weak-item{
            color: var(--gray) !important;
        }
    }
}

#customCalendar .__datepicker-dropdown-body .__datepicker-dropdown-body-header{
    direction: rtl !important;
    border-bottom: unset;
    margin-bottom: 0px;
}
#customCalendar .__datepicker-dropdown-body .__datepicker-dropdown-body-header .__datepicker-dropdown-body-controller:nth-child(1){
    transform: rotate(180deg) !important;
    -webkit-transform: rotate(180deg) !important;
    -moz-transform: rotate(180deg) !important;
    -ms-transform: rotate(180deg) !important;
    -o-transform: rotate(180deg) !important;
}
#customCalendar .__datepicker-dropdown-body .__datepicker-dropdown-body-header .__datepicker-dropdown-body-controller:nth-child(3){
    transform: rotate(0deg) !important;
    -webkit-transform: rotate(0deg) !important;
    -moz-transform: rotate(0deg) !important;
    -ms-transform: rotate(0deg) !important;
    -o-transform: rotate(0deg) !important;
}
#customCalendar .__datepicker-dropdown-body .__datepicker-dropdown-body-header .__datepicker-dropdown-body-controller .__datepicker-icon{
    background-color: var(--main-gray)
}
.__datepicker-pick-day-container .__datepicker-pick-day .__datepicker-days{
    font-size: 16px;
}
.__datepicker-pick-day-container .__datepicker-weak .__datepicker-weak-item{
    font-size: 16px;
}
.__datepicker-pick-day-container .__datepicker-pick-day .__datepicker-days:hover:not(.__datepicker-day-disabled):not(.__datepicker-today):not(.__datepicker-selected){
    background: var(--white);
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}
.__datepicker-pick-day-container .__datepicker-pick-day .__datepicker-days.__datepicker-selected{
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}
.__datepicker-pick-day-container .__datepicker-pick-day .__datepicker-days.__datepicker-today{
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}
.__datepicker-dropdown .__datepicker-dropdown-footer{
    border-top: unset;
}
.todayButtonDatePicker{
    background-color: var(--main-gray) !important;
}


// when you click on calendar, then borders and labels have to be blue.

.customCalendar {
    font-size: 0.8rem;
    position: relative;

    .childrenLabelBoxTitle {
        top: 1rem;
        transition: 0.099s;
    }
    input{
       color: white
    }

    .removeValue{
        position: absolute;
        display: flex;
        left: 15px;
        color: white;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}

.customCalendar:has(.__datepicker-dropdown) {
    .childrenLabelBoxTitle {
        top: -0.75rem;
        transition: 0.099s;
        color: var(--main-blue);;
    }
    .labelBox {
        border: 1.5px solid var(--main-blue);;
    }
}

.customCalendar:has(.calendarInput:not(:placeholder-shown)) .childrenLabelBoxTitle {
    top: -0.75rem;
    transition: 0.099s;
}

.calendarInput::placeholder {
    color: transparent;
}

.calendarInput {
    // padding: 1rem 0.75rem;
}

@media (max-width: 992px) {
    .__datepicker-pick-day-container .__datepicker-pick-day .__datepicker-days {
        width: 35px;
        height: 35px;
    }
}

.__datepicker-dropdown.right-top {
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    right: unset;
    bottom: unset;
}