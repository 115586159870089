.loadingHolder {
    padding: 0;
    margin: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #1E2235;
    flex-direction: column;

    .text {
        margin-top: 15px;
        font-size: 19px;
        color: var(--main-blue);
        font-weight: bold;
    }
}

.spinnerLoading {
    width: 4em;
    height: 4em;
    border: 0.5em solid rgba(0, 0, 0, 0.1);
    border-left-color: #7983ff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.fullPageLoading {
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;

    .loadingHolder {
        background-color: #7a7a7aba;
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}