@mixin before($top, $left, $width, $height, $color) {
    content: "";
    position: absolute;
    top: $top;
    left: $left;
    width: $width;
    height: $height;
    background-color: $color;
}

@mixin font($color, $size, $weight, $line) {
    font-weight: $weight;
    font-size: $size;
    line-height: $line;
    color: $color;
}

@mixin imgRatio($paddingTop) {
    width: 100%;
    height: 100%;
    padding-top: $paddingTop;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@mixin mediaQuery($viewPort) {
    @media (max-width: $viewPort) {
        @content;
    }
}

@mixin modalBg() {
    .modal-content {
        overflow-x: hidden;
        background-color: #252A41 !important;
    }

    .modal-body {
        padding: 0;
    }
}

@mixin floatLabel($goUp: -165%) {
    // .form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    //     opacity: 1 !important;
    //     transform: translateY( $goUp ) !important;
    //     background-color: #1E2235; 
    //     color: rgb(37, 99, 235) !important;
    // }

    .form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-select~label {
        background-color: #1E2235; 
        color: var(--main-blue) !important;
    }

    .form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
        opacity: 1;
        transform: translateY( $goUp ) !important;
        background-color: #1E2235; 
    }

    // .form-floating>.form-control-plaintext:focus, .form-floating>.form-control-plaintext:not(:placeholder-shown), .form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
    //     border: 1px solid var(--main-blue); !important;
    // }

    .form-floating>.form-control-plaintext:focus, .form-floating>, .form-floating>.form-control:focus {
        border: 1px solid var(--main-blue) !important;
    }
}


.direction-ltr{
    direction: ltr;
}

.color-green {
    color: var(--main-green);;
}
.color-red {
    color: var(--main-red);;
}
.color-orange {
    color: var(--main-orange);
}

/* ----------------------------- color variables ---------------------------- */

.darkMode {
    --main-green: #22C55E;
    --main-orange: #F59E0B;
    --main-orange2: #CA8A04;
    --main-yellow: #EAB308;
    --main-red: #EF4444;
    --main-blue: #2563eb;
    --main-purple: #c026d3;
    
    --dark-blue: #252A41;
    --dark-blue2: #1E2235;
    --dark-blue3: #1D2236;
    --dark-blue4: #303650;
    --dark-blue5: #353d60;
    --dark-blue6: #424867;
    --dark-blue7: #1e2235;
    --dark-blue8: #2e3451;
    --dark-blue9: #323855;
    --dark-blue10: #292E47;
    --dark-blue11: #1A1D2E;
    --dark-blue12: #191c2b;
    --dark-blue13: #4e567b;
    --dark-blue14: #4e5883;
    --dark-blue15: #252a41;
    
    
    --light-blue: #ABADC6;
    --light-blue2: #94A3B8;
    --light-blue3: #DCDEF3;
    --light-blue4: #575f7c75;
    --light-blue5: #535a81;
    --light-blue6: #5d6aa7;
    --light-blue7: #7F7F86;
    --light-blue8: #7F7F86;
    --light-blue9: #64748B;
    --light-blue10: #BFDBFE;
    --light-blue11: #5863a1;
    --light-blue12: #485183;
    --light-blue13: #575f86;
    --light-blue14: #666d92;
    --light-blue15: #abadc6;
    
    --main-border-gray: #ffffff2e;
    --main-gray: #949494;
    --main-gray2: #0000001a;
    --white: #fff;
    --white2: #ffffff85;
    --black: #1E1E1E;
    --black2: #1C1917;
    --black3: #555;
    --black-low-opacity: #00000026;
    --complete-black: #000;
    --modalBg: #00000014;
    --inputLabel: rgba(255, 255, 255, 0.52);
}


.lightMode {
    --main-green: #22C55E;
    --main-orange: #F59E0B;
    --main-orange2: #CA8A04;
    --main-yellow: #EAB308;
    --main-red: #EF4444;
    --main-blue: #2563eb;
    --main-purple: #c026d3;
    
    --dark-blue: #b1bbeb;
    --dark-blue2: #b1bbeb;
    --dark-blue3: #b1bbeb;
    --dark-blue4: #b1bbeb;
    --dark-blue5: #b1bbeb;
    --dark-blue6: #b1bbeb;
    --dark-blue7: #b1bbeb;
    --dark-blue8: #b1bbeb;
    --dark-blue9: #b1bbeb;
    --dark-blue10: #b1bbeb;
    --dark-blue11: #b1bbeb;
    --dark-blue12: #b1bbeb;
    --dark-blue13: #b1bbeb;
    --dark-blue14: #b1bbeb;
    
    --light-blue: #fff;
    --light-blue2: #fff;
    --light-blue3: #fff;
    --light-blue4: #575f7c75;
    --light-blue5: #535a81;
    --light-blue6: #5d6aa7;
    --light-blue7: #7F7F86;
    --light-blue8: #7F7F86;
    --light-blue9: #64748B;
    --light-blue10: #fff;
    --light-blue11: #5863a1;
    --light-blue12: #485183;
    --light-blue13: #575f86;
    
    
    --main-gray: #949494;
    --main-gray2: #0000001a;
    --white: #000;
    --black: #fff;
    --black2: #fff;
    --complete-black: #fff;
    --modalBg: #00000014;
    --inputLabel: rgba(255, 255, 255, 0.52);
}