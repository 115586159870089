@import "./globals.scss";

.ltr * {
    // direction: ltr;
}

.fromHolder {
    width: 100vw;
    height: 100vh;
    background-color: var(--dark-blue3);
    position: relative;
}

.formMarginTop {
    margin-top: 5rem;
}

.signUp {
}

.notHavingAccount, .signUpLink {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--light-blue);
}

@mixin signUpLink {
    color: var(--main-blue);
    text-decoration: none;
}
.signUpLinkCenter{
    @include signUpLink();
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.formgp {
    position: relative;
    margin-bottom: 2rem;
}

.InputMarginButton {
    margin-bottom: 3rem;
}

.myInputClass__control {
    background-color: transparent !important;
}

.myInputClass__menu {
    background-color: var(--light-blue) !important;
}

.labels {
    color: var(--white);
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    position: absolute;
    top: -0.75rem;
    right: 0.5rem;
    padding: 0 0.5rem;
    background-color: var(--dark-blue3);
}

.formInputs {
    background-color: transparent;
    color: var(--white) !important;
    border: 2px solid var(--dark-blue4) !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.formInputs:focus {
    background-color: transparent;
    color: var(--white);
}

.formInputs::placeholder {
    color: var(--light-blue4);
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.formInputs_email, .formInputs_email::placeholder {
    text-align: left;
}

.form, .formOTP {
    width: 25%;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

@media (max-width: 992px) {
    .form {
        width: 60%;
    }
}

.formOTP {
    width: 12%;
}

@media (max-width: 1540px) {
    .formOTP {
        width: 15%
    }
}

@media (max-width: 1440px) {
    .formOTP {
        width: 18%
    }
}

@media (max-width: 1440px) {
    .formOTP {
        width: 27%
    }
}

@media (max-width: 790px) {
    .formOTP {
        width: 33vw;
    }
}

@media (max-width: 600px) {
    .formOTP {
        width: 45vw;
    }
}

@media (max-width: 450px) {
    .formOTP {
        width: 63vw;
    }
}


@media (max-width: 340px) {
    .formOTP {
        width: 74vw;
    }
}

.imgHolder img {
    width: 100%;
    object-fit: contain;
}

.forgetPassLink {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--main-blue);
    text-decoration: none;
}

.editNumber {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: var(--main-blue);
    margin-top: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.numberTxt {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
}

.editNumber a {
    text-decoration: none;
}

.otpSystem {
    justify-content: center;
    margin-top: 1rem;
    display: flex;
}

.otpParent {
    margin-left: 1rem;
    &:first-of-type {
        margin-left: auto;
    }
}

.ltr .otpParent {
    margin-left: auto;
    margin-right: 1rem;
    &:first-of-type {
        margin-right: auto;
    }
}

.spaceBetweenOtp {
    display: none;
}

div:has(.otpParent) {
    flex-direction: row-reverse;
}

.otpSystem input {
    background-color: transparent;
    width: 40px !important;
    height: 40px;
    border: 2px solid var(--dark-blue4);
    border-radius: 10px;
    color: var(--white);
}

.secondsToEnd {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: var(--main-blue);
}

.sendAgainTxt {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
}

.submitBtnModal {
    width: 60px;
}

.modalBackground {
    background: var(--black);
}

.alertWarning, .alertSuccess {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: var(--main-orange);
}

.alertSuccess { 
    color: var(--main-green);
}

.alertContent {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--light-blue);
}

.errEmail {
    position: absolute;
    top: 3rem;
    font-size: 11px;
}

.authAlerts .modal-content {
    height: fit-content;
}