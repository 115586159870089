@import "./globals.scss";

.offcanvas-header {
    padding-top: 2rem;
}

.offcanvas-header button {
    background: var(--dark-blue6);
    border-radius: 12px;
    padding: 0 !important;
    width: 36px;
    height: 36px;
    background-image: url("../img/closeSidebar.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.sidebar_dekstop {
    height: 100%;
    top: 0;
    display: flex;
    position: fixed;
}

.sidebar_dekstop_items {
    flex-grow: 1;
    overflow-y: scroll;
}

.logoHolder {
    padding-top: 1rem;
    padding-bottom: 2.063rem;

    img {
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .logoHolder {
        padding-top: 0;
        position: absolute;
        top: 27px;
        left: 20px;
        width: 180px;
    }

    .ltr .logoHolder {
        left: auto;
        right: 0;
    }
}

.ltr .logoHolder {
    justify-content: center;
}

@media (min-width: 1920px) {
    .sidebar_dekstop, .logoHeader {
        width: 15%;
    }
}

@media (max-width: 1920px) {
    .sidebar_dekstop, .logoHeader {
        width: 15%;
    }
}

@media (max-width: 1525px) {
    .sidebar_dekstop, .logoHeader {
        width: 20%;
    }
}

@media (max-width: 1200px) {
    .sidebar_dekstop, .logoHeader {
        display: none;
    }
}


.sidebar_mobile {
    width: 5.1%;
    position: fixed;
    top: 0;
    z-index: 11;
}

@media (min-width: 1200px) {
    sidebar_mobile {
        display: none;
    }
}

.sidebarHeight {
    height: 100%;
    margin-right: 0.35rem;
}

@media (max-width: 1200px) {
    .sidebarHeight {
        margin-right: 0;
    }
}

.sidebarBg {
    background-color: var(--dark-blue2) !important;
    padding-top: 5rem;
}

.sidebarBg_mobile {
    background-color: var(--dark-blue);
    width: 25% !important;
    left: auto !important;
    right: 0 !important;
}

@media (max-width: 1050px) {
    .sidebarBg_mobile {
        width: 30% !important;
    }
}

@media (max-width: 900px) {
    .sidebarBg_mobile {
        width: 60% !important;
    }
}

@media (max-width: 460px) {
    .sidebarBg_mobile {
        width: 100% !important;
    }
}

.sidebarBg_accordion {
    /* padding-bottom: 0;
    padding-top: 0.5rem; */
}

.sidebarBg_accordion, .sidebarBg_accordion button {
    background-color: transparent !important;
    color: var(--light-blue);
    border: none !important;
    padding-left: 0px;
}


.sidebarBg_accordion, .accordionBtn button,  .accordionBtn button[aria-expanded="true"]{
    background-color: transparent;
    color: var(--white);
}

.navbarSidebar.accordion:has(.d-block.active) {
    background: var(--dark-blue);
    border-radius: 12px;
}

.sidebarItemAcc {
    background-color: transparent !important;
    border: none !important;
    padding: 0.75rem 1rem;
    padding-left: 0;
}

.ltr .sidebarItemAcc {
    margin-left: 14px;
}

.sidebarItemAcc button{
    padding-right: 0;
    width: 100% !important;
}

.sidebarItemAcc button:not(.collapsed) {
    box-shadow: none;
}

.sidebarItemAcc button::after {
    background-color: var(--light-blue);
    margin-right: 10px;
    border-radius: 5px;
}

.sidebarBtn {
    display: none;
    padding: 1.3rem 0;
    border: none;
    background-color: var(--dark-blue2);
    color: var(--white);
}

.sidebarBtn  img {
    transform: rotate(180deg);
}

@media (max-width: 1200px) {
    .sidebarBtn {
        display: block;
        padding: 1.75rem 0;
    }
}

.sidebarItems {
    padding: 1rem;
    text-decoration: none !important;
}

.sidebarItems-child span {
    /* font-size: 14px; */
}

.activeLink.active .sidebarItems_eng {
    /* color: #Fff; */
}

.sidebarItmesColor {
    /* width: 92%;
    padding: 0.75rem 1rem;
    text-decoration: none;
    color: var(--light-blue);
    transition: 0.3s;
    text-align: right;
    padding-left: 0;
    padding-right: 0; */
}

.sidebarItmesColor.active {
    /* color: var(--white); */
}

.sidebarItmesColor:hover {
    /* color: var(--white); */
}

.sidebarItmesColor_active {
    /* background-color: var(--dark-blue);
    color: var(--white);
    border-radius: 12px;
    width: 90%;
    transition: 0.3s; */
}

.generalItems {
    position: absolute;
    top: 95%;
    transform: translateY(-95%);
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--dark-blue2); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--light-blue14); 
    height: 30px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--black3); 
  }

  .sidebarItemsAccordion {
    width: 100%;
    color: var(--light-blue15);
    padding: 0.75rem 0;
    text-align: right;
    text-decoration: none;
    transition: .3s;
}

.ltr .sidebarItemsAccordion {
    text-align: left;
}

.sidebarLinks {
    width: 92%;
    padding: 0.75rem 1rem;
    text-decoration: none;
    color: var(--light-blue);
    transition: 0.3s;
    text-align: right;
    padding-left: 0;
    padding-right: 0;
}

.ltr .sidebarLinks {
    padding-left: 19px;
    margin-left: 15px;
}

.sidebarItems_NoChild {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: var(--light-blue);
    text-decoration: none;
    transition: 0.3s;
}

.ltr .sidebarItems_NoChild {
    text-align: left;
}

.sidebarLinks .sidebarItems_eng {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: var(--light-blue);
    text-decoration: none;
    transition: 0.3s;
    padding-right: 10px;

    &:hover {
        color: var(--white);
    }
}

.ltr .sidebarLinks .sidebarItems_eng {
    text-align: left;
    padding-right: 0;
}

.sidebarLinks:hover {
    color: var(--white);
}

.sidebarLinksActive {
    background: var(--dark-blue15);
    border-radius: 12px;
    color: var(--white) !important;
}

.sidebarSubAccordion {
    padding-right: 14px;
}

.sidebarItemAcc .accordion-collapse:first-of-type {
    margin-top: 0.5rem;
}

.sidebarLinksNoChild {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.accordion-body {
    padding: 0;
}

  /* .navItems {

    .badge{
        width: 20px;
        height: 20px;
        margin-right: auto;
        background: #2563EB;
        border-radius: 200px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
    }

    &.active{
        color: var(--white);
        background: var(--dark-blue);
        border-radius: 12px;
    }
} */