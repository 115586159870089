@import "./globals.scss";

@mixin inputLabel() {
    width: fit-content;
    right: 0;
    height: fit-content;
    top: 1.85rem;
    transform: translateY(-50%);
    padding: 0 0.5rem;
    right: 0.75rem;
    z-index: 2;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 100% 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    position: absolute;
    color: rgba(255, 255, 255, 0.52);
    font-size: 0.8rem;
}

@mixin inputLabelUp() {
    transform: translateY(-165%) !important;
    background-color: var(--dark-blue2) !important;
    opacity: 1 !important;
    -webkit-transform: translateY(-165%) !important;
    -moz-transform: translateY(-165%) !important;
    -ms-transform: translateY(-165%) !important;
    -o-transform: translateY(-165%) !important;
}

@mixin inputLabelUpFocus() {
    @include inputLabelUp();
    color: var(--main-blue) !important;
}

.switchBtn {
    border: 2px solid var(--dark-blue6);
    color: var(--dark-blue6);
    display: flex;
    // width: 140px;
    align-items: center;
    justify-content: center;
    border-radius: 100px;

    .item {
        min-width: 70px;
        text-align: center;
        padding: 7px;
        cursor: pointer;
    }

    .item.selected {
        background: var(--main-blue);
        ;
        border-radius: 100px;
        color: white;
    }
}

.dashBoxDescription {}

/* ------------------------------ Autocomplete ------------------------------ */

.myInputClass__input {
    color: var(--white) !important;
}

.myInputClass__menu {
    z-index: 3 !important;
}

.css-1s2u09g-control {
    border: 1px solid transparent !important;
    color: var(--white);

    &:hover {
        border: 1px solid transparent !important;
    }
}

.css-1pahdxg-control {
    border: 1px solid transparent !important;
    box-shadow: none !important;
}

/* ------------------------------ Float Inputs ------------------------------ */

.customFloatInput {
    input {
        background-color: transparent !important;
        color: var(--white) !important;
        padding-top: 1rem !important;

        &:focus {
            border: 1.5px solid var(--main-blue) !important;
        }

        &:focus+label {
            @include inputLabelUpFocus()
        }

        &:not(:placeholder-shown)+label {
            @include inputLabelUp()
        }
    }

    textarea {
        min-height: 130px;

        &:focus {
            border: 1.5px solid var(--main-blue) !important;
        }

        &:focus+label {
            @include inputLabelUpFocus()
        }

        &:not(:placeholder-shown)+label {
            @include inputLabelUp()
        }
    }

    label {
        @include inputLabel()
    }

    .form-floating>.form-control:focus~label {
        @include inputLabelUpFocus()
    }

    .form-floating>.form-control:not(:placeholder-shown)~label {
        @include inputLabelUp()
    }

    .form-floating>.form-control:focus {
        border: 1px solid var(--main-blue) !important;
    }

    // @include floatLabel(-165%);

}

.ltr .customFloatInput label {
    left: 1rem;
}

.selectInput .childrenLabelBoxTitle {
    color: var(--main-gray);
}

.selectInput:has(.show.dropdown) {
    .childrenLabelBoxTitle {
        color: var(--main-blue);
        ;
        transition: 0.099s;
    }

    .labelBox {
        border: 1.5px solid var(--main-blue);
        ;
    }
}

.selectInputInteraction {
    background-color: var(--main-blue);
    ;
}

.ant-upload-select {
    display: none !important;
}

.ltr .selectBoxTitle {
    width: 100%;
    display: inline-block;
    text-align: left;
}

.addProjectInput.dropdown-toggle::after {
    position: absolute;
    left: 0;
    top: 50%;
}

.ltr .addProjectInput.dropdown-toggle::after {
    left: auto;
    right: 12px;
}

.ant-select-dropdown {
    z-index: 1056;
    background-color: var(--dark-blue6);

    .ant-select-item-option-content {
        color: var(--white);
    }

    .ant-select-item {
        background-color: var(--dark-blue6) !important;
        border: none !important;
        box-shadow: none;
    }

    .ant-select-item-empty {
        display: none;
    }

    
    .ant-select-item-option-active {
        background-color: #4d536e !important
    }
}

.SingleSelectAutoComplete-modal {
    .ant-select {
        width: 100%;
    }

    .ant-select-arrow {
        color: var(--white);
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
        color: var(--white2);
        display: flex;
        align-items: center;
    }

    .ant-select-selection-item {
        color: var(--white);
    }

    .ant-select-selection-search {
        position: relative;

        input {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: var(--white);
        }
    }

    .ant-select-selector {
        border: 1px solid var(--dark-blue6) !important;
        border-radius: 10px !important;
        background-color: transparent !important;
        min-height: 58px !important;
    }

    .ant-select-clear {
        background-color: none;
    }

    .inputLabel {
        @include inputLabel()
    }

    .inputLabel.hover {
        @include inputLabelUp();
    }

    .ant-select-focused+label {
        @include inputLabelUpFocus();
    }

    .ant-select-focused {
        .ant-select-selector {
            border: 1.5px solid var(--main-blue) !important;
        }
    }
}

.multiSelectAutoComplete-modal {
    .ant-select-selection-item {
        background-color: var(--dark-blue5);
        color: var(--white);
    }
    .ant-select-selector {
        padding-top: 15px;
    }

    .anticon.anticon-close {
        color: var(--white2);
    }
}

.switchInModal {
    .ant-switch {
        background-color: var(--dark-blue5);
    }

    .ant-switch-checked {
        background-color: var(--main-blue);
    }
}

.currency,
.inputUnit {
    position: absolute;
    top: 1rem;
    right: 2rem;
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    color: var(--inputLabel);
}

.ant-switch{
    transition: 0.6s;
    -webkit-transition: 0.6s;
    -moz-transition: 0.6s;
    -ms-transition: 0.6s;
    -o-transition: 0.6s;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
.ant-switch:focus{
    border: solid 1px var(--main-blue);
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
}