@import "./globals.scss";

body {
    background-color: var(--dark-blue11) !important;
}


// @import url("../fonts/css/fontiran.css");

* {
    &:focus {
        box-shadow: none !important;
    }

    &:focus-visible {
        outline: none !important;
    }
}

.modalBaseStyle .modal-content {
    background-color: var(--dark-blue2) !important;
    overflow-x: hidden;
}

.invoiceCostCurrency {
    color: var(--white);
    font-size: .75rem;
    font-weight: 300;
    line-height: 18px;
}

.invoiceTableFont {
    color: var(--white);
    font-size: .875rem;
    font-weight: 500;
    line-height: 21px;
    position: relative;
}

.contractsTitle {
    align-items: center;
    color: var(--white);
    display: flex;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 31px;
}

.homeDate {
    color: var(--light-blue);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.addInvoiceBtnFont {
    color: var(--white);
    font-size: 1rem;
    font-weight: 600;
    line-height: 25px;
}

.mainContent {
    padding-top: 5rem;
    padding-right: 15%;
}

.ltr .mainContent {
    padding-right: 0;
    padding-left: 15%;
}

@include mediaQuery(1525px) {
    .mainContent {
        padding-right: 20%;
    }

    .ltr .mainContent {
        padding-right: 0;
        padding-left: 20%;
    }
}

@include mediaQuery(1200px) {
    .mainContent {
        padding-right: 0;
        padding-left: 0;
    }
}

textarea {
    color: var(--white) !important;
}

.containerCustom {
    padding-left: 20px;
    padding-right: 20px;
}

.form-control {
    border-radius: 10px !important;
    border: 1px solid var(--dark-blue6) !important;
}

.JDatePicker {
    z-index: 3 !important;

    * {
        color: var(--complete-black);
    }
}

.calendarToggle-off .JDatePicker {
    display: none;
}

.calendarToggle-on .JDatePicker {
    display: block;
}

.darkBg {
    background-color: var(--dark-blue11);
}

.w-90 {
    width: 90%;
}

.w-80 {
    width: 80%;
}

.w-70 {
    width: 70%;
}

.w-60 {
    width: 60%;
}

.w-40 {
    width: 40%;
}

.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

.w-10 {
    width: 10%;
}

@include mediaQuery(992px) {
    .w-mobile-100 {
        width: 100% !important;
    }
}

.h-90 {
    height: 90%;
}

.noneDesktop {
    display: none;
}

.noneMobile {
    display: inline-block;
}

/* ------------------------- general mobile classes ------------------------- */

.modalContentHolder:has(.offlineModal) {
    padding-bottom: 25px;
}

@media (max-width: 992px) {
    .noneMobile {
        display: none;
    }

    .noneDesktop {
        display: block;
    }

    .mb-lg-2 {
        margin-bottom: 1rem;
    }

    .me-lg-2 {
        margin-left: 1rem;
    }

    .mx-lg-0 {
        margin-left: 0rem !important;
        margin-right: 0rem !important;
    }

    .mx-lg-1 {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }

    .mt-mobile-2 {
        margin-top: 2rem !important;
    }

    .justify-content-center_lg {
        justify-content: center !important;
    }

    .w-lg-100 {
        width: 100%;
    }
}

.options {
    width: 100%;
}

/* ---------------------------------- date ---------------------------------- */

.date {
    justify-content: end;
}

@media (max-width: 992px) {
    .date {
        justify-content: flex-start;
    }
}

/* ----------------------------- general classes ---------------------------- */

.draftsTxt {
    margin-right: 1rem;
    font-size: 13px;

    a {
        text-decoration: none;
        color: var(--white);
        transition: 0.3s;

        &:hover {
            color: var(--white);
        }
    }
}

.costInput input {
    text-align: left;
    padding-right: 4rem !important;
}

.fullWidthAndHeight {
    width: 100%;
    height: 100%;
}

.btnSectionInModal {
    button {
        width: 100px;
    }
}

.btnSectionInModal,
.multiBtnSectionInModal {
    position: absolute;
    width: 100%;
    bottom: 0vh;
    left: 0;
    padding: 1rem;
    z-index: 3;
    background-color: var(--dark-blue5);
}

.flex-grow-2 {
    flex-grow: 2;
}

@media (max-width: 992px) {
    .flex-grow-2-mobile {
        flex-grow: 2;
    }

    .column-mobile {
        flex-direction: column;
    }

    .justify-content-end-mobile {
        justify-content: end !important;
    }

    .align-items-start-mobile {
        align-items: flex-start !important;
    }
}

.input {
    direction: rtl;
    display: flex;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    margin-right: 1rem;
}

.leftInput {
    input {
        text-align: left;
        direction: ltr;
    }
}

.input,
.searchInput {
    color: var(--light-blue3);
    background: var(--dark-blue);
    border: none;
}

.searchInput {
    flex-grow: 2;
}

@media (max-width: 460px) {
    .searchInput {
        width: 100px;
    }
}

.Kbg {
    background-color: var(--dark-blue6);
    border-radius: 7px;
    display: flex;
    align-items: center;
    padding: 0.5rem;
}


.accordion-button {
    width: 17px !important;
    height: 17px !important;

    &::after {
        display: none;
    }
}

.accordion-button .accordionSidebarIcon {
    transform: rotate(180deg);
    transition: 0.3s;
}

.accordion-button.collapsed .accordionSidebarIcon {
    transform: rotate(0);
    transition: 0.3s;
}

.navItems {

    // .badge{
    //     width: 20px;
    //     height: 20px;
    //     margin-right: auto;
    //     background: var(--main-blue);;
    //     border-radius: 200px;
    //     text-align: center;
    //     display: inline-flex;
    //     align-items: center;
    //     justify-content: center;
    //     margin-left: 10px;
    // }

    // &.active{
    //     color: var(--white);
    //     background: var(--dark-blue);
    //     border-radius: 12px;
    // }
}

@media (max-width: 992px) {
    .mobile_none {
        display: none;
    }
}

.content {
    padding: 6rem 15% 0 0;
}

.ltr .content {
    padding: 6rem 0 0 15%;
}

@media (max-width: 1525px) {
    .content {
        padding: 6rem 20% 0 0;
    }

    .ltr .content {
        padding: 6rem 0 0 20%;
    }
}

@media (max-width: 1200px) {
    .content {
        padding: 6rem 0 0 0;
    }

    .ltr .content {
        padding: 6rem 0 0 0%;
    }
}

.topUrl {
    text-decoration: none;
    transition: 0.3s;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--light-blue);
}

.topUrl:hover {
    color: var(--white);
}

.addProjectBtn {
    padding: 0.6rem 1rem;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--light-blue);
    background-color: transparent;
    border: 2px solid var(--dark-blue6);
    border-radius: 12px;
}

@media (max-width: 992px) {
    .addProjectBtn {
        width: 100%;
        margin: 1rem 0;
    }
}

.notPointer {
    cursor: default !important;

    button {
        cursor: default !important;
    }
}

.pointer {
    cursor: pointer !important;
}

.halfOpacity {
    opacity: 0.5 !important;
}

.completeOpacity {
    opacity: 1 !important;
}

.completeOpacity:focus {
    background: #8badf9;
    border-color: #8badf9;
}

.num2Persian {
    @include font(var(--light-blue7), 0.75rem, 400, 21px);
    text-align: right;
    display: block;
    margin: 0.5rem 0;
    margin-left: 0.5rem;
}

.ltr .num2Persian {
    text-align: left;
}

.today {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: var(--light-blue);
}

.timeBorder {
    border-left: 2px solid var(--dark-blue);
    border-right: 2px solid var(--dark-blue);
}

.blueBtn {
    background: var(--main-blue);
    opacity: 0.79;
    border-radius: 12.8571px;
    padding: 1rem;
}

.addBtnBox {
    background: var(--dark-blue10);
    border-radius: 12px 12px 0px 0px;
}

.addBtnsHolder {
    bottom: 30%;
    width: 100%;

    .addBtnBox {
        position: absolute;
        bottom: 0vh;
        z-index: 2;
    }
}

.modalDetailsHolder {
    height: 70%;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* --------------------------------- select --------------------------------- */

.titleSelect,
.titleNotSelect {
    &:hover {
        background-color: transparent !important;
        color: var(--white) !important;
    }
}

.titleNotSelect {
    color: var(--main-blue) !important;
}

.titleSelect {
    color: var(--white) !important;
}

.selectDropDonw {
    button {
        width: 100%;
        text-align: right;
        background-color: transparent;
        border: none;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        color: var(--light-blue4);
    }

    &:hover,
    &:active {
        color: var(--bs-btn-hover-color);
        background-color: transparent;
    }

    .dropdown-menu {
        width: 100%;
        text-align: right;
        background-color: var(--light-blue11);

        a {
            color: var(--white);

            &:hover {
                background-color: var(--light-blue12);
                color: var(--white);
            }
        }
    }
}


.selectDropDonw button::after {
    position: absolute;
    top: 45%;
    left: 1rem;
}

/* ---------------------------- add in other part --------------------------- */

.fastAddHolder {
    width: 100%;
    display: flex;
    justify-content: end;
}

@include mediaQuery(992px) {
    .fastAddHolder {
        justify-content: center;
    }
}

.fastAddBtn {
    position: relative;
}

@include mediaQuery(992px) {
    .fastAddBtn {
        position: fixed !important;
        top: 90%;
    }
}

.fastAddItemsHolder {
    position: absolute;
    bottom: 110%;
    left: 60%;
    width: 150px;
}

@include mediaQuery(992px) {
    .fastAddItemsHolder {
        left: 50%;
        transform: translateX(-50%);
    }
}


.addInOtherPart {
    padding: 0.75rem;
    background: var(--main-blue);
    border-radius: 12px;
    transition: 0.2s;
    @include font(var(--white), 1.125rem, 700, 28px);
    cursor: pointer;
    animation: comeUp 2s;

    &:hover {
        background: var(--main-blue);
    }
}

@keyframes comeUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

.rotatePlusImg_45 {

    background-color: var(--main-red);
    transition: 0.2s;

    img {
        transform: rotate(45deg);
        transition: 0.2s;
    }
}

.fastAddBox {
    width: 150px;

    .fastAddItems {
        width: 100%;

        .addInOtherPart {
            width: 100%;
        }
    }
}

.rotatePlusImg_0 img {
    transform: rotate(0deg);
    transition: 0.2s;
}

.fastAddItems {
    position: absolute;
    bottom: 100%;
    left: 0;
    transform: translate(0, -5%)
}

@include mediaQuery(992px) {

    .fastAddBpx {
        width: 100%;
    }

    .fastAddItems {
        width: 50% !important;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, -35%)
    }
}

/* --------------------------------- modals --------------------------------- */

.modal {
    background-color: var(--modalBg);
    backdrop-filter: blur(5px);
}

.modal-body {
    position: unset;
}

.modalHeaderHolder {
    height: 10%;
    width: 100%;
    position: absolute;
}

.modalContentHolder {
    max-height: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
    padding-bottom: 100px;

    &.noneFooter {
        padding-bottom: 10px;
    }
}


.modal-dialog {
    height: calc(100% - 20px);
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.modal-content {
    // height: 100%;
    position: relative;
    overflow-y: hidden
}

.modalHeaderTitle {
    @include font(var(--white), 1.125rem, 600, 28px);
}

.modalHeaderTitle {
    display: block;
    text-align: center;
}

.closeModalImg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.modalHeaderBorderBottom {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: var(--dark-blue);
    z-index: 3;
}

.modalBaseStyle {
    .modal-title {
        width: 100%;
    }

    .modal-header {
        position: relative;
        border-bottom: 1px solid var(--dark-blue9);
        box-shadow: 0px 7px 10px 0px var(--dark-blue12);
    }
}

.modalBaseStyle {
    .modal-content {
        background-color: var(--dark-blue2) !important;
        overflow-x: hidden;
    }

    .modal-body {
        padding: 0
    }
}

@media (min-width: 992px) {
    .modalBaseStyle.xlg .modal-dialog {
        max-width: 90%;
    }

    .modalBaseStyle .modal-dialog {
        max-width: 50%;
    }

    .modalBaseStyle.xl .modal-dialog {
        max-width: 90%;
    }

    .modalBaseStyle.sm .modal-dialog {
        max-width: 30%;
    }
}

.modalHeaderTitler {
    width: 100%;
    text-align: center;
    @include font(var(--light-blue), 1.125rem, 700, 28px);
}

.modalHeaderBorderBottom::before {
    @include before(100%, -30%, 150%, 1px, var(--dark-blue9))
}

/* ------------------------------- dashed box ------------------------------- */
/* ---------------------------------- date ---------------------------------- */

.datePosition {
    justify-content: end;
}

@include mediaQuery(992px) {
    .datePosition {
        justify-content: start;
        margin: 1.5rem 0;
    }
}

.tableHolder {
    background: var(--dark-blue2);
    border-radius: 12px;
    padding: 2rem;
    margin-top: 1.5rem;

    &.fullPage {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        margin: 0;
        z-index: 10;

    }
}

@media (max-width: 992px) {
    .tableHolder {
        background-color: transparent;
        padding: 0;
    }
}

/* -------------------------------- label box ------------------------------- */

.labelBox {
    border: 1.5px solid var(--dark-blue6);
    border-radius: 10px;

    input {
        background-color: transparent;
        border: none
    }
}

.dashBox {
    border: 3px dashed var(--dark-blue6);
    // border: 1px solid var(--dark-blue6);
    position: relative;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.dashBoxLabel {
    @include font(var(--main-blue), 0.875rem, 400, 24px);
    color: var(--main-blue);
    position: absolute;
    top: -0.9rem;
    right: 0.3rem;
    background-color: var(--dark-blue2);
    padding: 0 0.3rem;
}

.ltr .dashBoxLabel {
    right: auto;
    left: 0.83rem;
}


.childrenLabelBoxTitle {
    // @include font(var(--main-blue);, 0.75rem, 400, 21px);
    // color: var(--main-blue);;
    font-weight: 400;
    line-height: 21px;
    position: absolute;
    top: -0.7rem;
    right: 1rem;
    padding: 0 0.5rem;
    z-index: 0;
}

.ltr .childrenLabelBoxTitle {
    right: auto;
    left: 1rem;
}

.inputCurrency {
    @include font(var(--white), 10px, 400, 24px);
}

/* ------------------------------- float input ------------------------------ */

.floatDescription {
    height: 100%;

    textarea {
        // border: none !important;
        background-color: transparent !important;
    }
}

.InputHasUnit input {
    padding-right: 4rem !important;
}

.exitText {
    @include font(var(--light-blue), 1rem, 500, 18px)
}

.exitBtns {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--dark-blue10);
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.dropdownborder {
    padding: 0.75rem;
    border-radius: 10px !important;
    border: 1px solid var(--dark-blue6) !important;

    .dropdown-menu {
        width: 100%;
        background-color: var(--dark-blue6);

        a {
            color: var(--white);
            text-align: right;

            &:active {
                background-color: var(--light-blue13);
            }

            &:hover {
                background-color: var(--light-blue13);
            }
        }
    }
}

.gallerySlides {
    width: 100px !important;

    a {
        cursor: default;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.gallerySlides_loading {
    position: relative;

    img {
        opacity: 0.5;
    }

    svg {
        color: var(--white);
    }

    .loadingImgGallery {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.profilePicUploader {
    position: relative;
    width: 88px;
    height: 88px;

    .profilePicUploader_imgHolder {
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .profilePicUploader_img {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -50% -50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .profilePicUploader_uploadIconHolder {
        position: absolute;
        bottom: -0.5rem;
        right: -0.5rem;
        z-index: 2;
        width: 44px;
        height: 44px;
        background-color: var(--dark-blue2);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
    }

    .profilePicUploader_uploadIcon {
        width: 45%;
    }
}

.Toastify__close-button {
    position: absolute;
    left: 1rem;
}

.Toastify__progress-bar {
    height: 3px;
}

.Toastify__toast-body {
    padding: 1rem
}

.Toastify__toast-icon+div {
    @include font(var(--light-blue), 12px, 400, 18px);
}

.Toastify__toast--error {
    border-radius: 12px;
    border-top: 1px solid var(--main-red);
    border-left: 1px solid var(--main-red);
    border-right: 1px solid var(--main-red);
    border-bottom: 2px solid var(--main-red);
}

.Toastify__toast--success {
    border-radius: 12px;
    border-top: 1px solid var(--main-green);
    border-left: 1px solid var(--main-green);
    border-right: 1px solid var(--main-green);
    border-bottom: 2px solid var(--main-green);
}

.urlNav,
.urlNoNav {
    @include font(var(--light-blue), 12px, 400, 18px);
    display: block;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
        color: var(--white);
    }
}

.labelBox {
    padding: unset;
}

.assignContract,
.assignTo // , .profileDetailsHolder 

    {
    .labelBox {
        padding: 1rem;
    }
}

.childrenLabelBoxTitle {
    color: var(--inputLabel);
}

.childrenLabelBox {
    padding: 1rem 0.75rem;
    padding-bottom: 0.625rem;
}

.tableDropdownResponsive {
    .addProjectInput {
        display: none;
    }

    .dropdown-menu,
    a {
        background: var(--dark-blue6);
        color: white;

        &:hover {
            background: var(--dark-blue6);
            color: white;
        }
    }
}

@include mediaQuery(992px) {
    .fullAddBtnMobile {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .pageTilteInMobile {
        margin-top: 2rem;
    }

    .tableDropdownResponsive {
        a {
            text-align: start;
        }
    }

    .tableDropdownResponsive {
        .addProjectInput {
            display: inline-block;
        }
    }
}

.tableDropdownResponsive {
    .dropdown-toggle::after {
        display: none;
    }
}

.contarctTableResponsiveTilte_withoutBorder {
    margin-bottom: 0rem !important;
    padding-bottom: 0rem !important;
}

.myInputClass__menu {
    div {
        background: var(--dark-blue6);
        color: white;
    }
}


.operationBtnTable {
    border-radius: 32px;
    padding: 0.25rem 0.5rem;
    background-color: var(--light-blue10);
    color: var(--main-blue);
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    text-align: center;

    a {
        color: var(--main-blue);
        text-decoration: none;
        text-align: center;
    }
}

.inputErrors {
    border: 1px solid var(--main-red) !important;
}

.text-error {
    color: var(--main-red);
}

.loadingUploadingPic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
}

.uploadPictureBtn {
    color: var(--white);
    width: 100px;
    height: 100px;
    border: 1px solid var(--main-blue);
    border-radius: 10px;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 40px !important;
        height: 35px !important;
    }
}

.cropBtnGallery {
    span {
        color: var(--white);
    }
}

.modalContentHolder:has(.cropImgHolder) {
    max-height: none;
    height: 85vh;
    padding-bottom: 0;
}

.cropImgHolder {
    width: 100%;
    height: 100%;
    position: relative;

    img {
        width: 100%;
        height: calc(100% - 15%);
        position: absolute;
        top: 44%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: contain;
    }
}

.modalContentHolder:has(.ReactCrop) {
    max-height: none;
    height: 85vh;
    padding-bottom: 0;
    display: flex;
    align-items: center;

    .App {
        margin-bottom: 5rem;
    }
}

// .ReactCrop {
//     width: 100%;
//     height: 99%;
//     position: relative;
//     img {
//         width: 100%;
//         height: calc(100% - 15%);
//         position: absolute;
//         top: 44%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         object-fit: contain;
//     }
// }

.ReactCrop__child-wrapper {
    width: 100%;
    height: 100%;
}

.invoicePage {
    .ant-pagination {
        display: none !important;
    }
}

.filterChips {
    background-color: var(--dark-blue5);
    color: var(--white);
    margin-top: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: 10px;
    cursor: pointer;
}

.filterChips-remove {
    color: var(--white2);
}

.trashIcon {
    color: var(--main-red);
    font-size: 18px;
    cursor: pointer;
}

.sequenceCheckBoxInput {
    width: 20px;
    height: 20px;
    background-color: transparent;
    position: relative;
    border-radius: 5px;

    &::after {
        @include before(-1px, -1px, 110%, 111%, var(--dark-blue2));
        border-radius: 50%;
        border: 2px solid var(--dark-blue6);
    }

    &:checked::after {
        display: none;
    }
}

.sequenceCheckBox {
    @include font(var(--white), 0.875rem, 400, 24px);
    margin-right: 0.5rem;
}

.sequenceAttributeTitle {
    @include font(var(--main-blue), 0.875rem, 400, 24px);
    display: block;
}

.draggerSection {
    .ant-upload-text {
        color: #ffffffba !important;
    }

    .ant-upload-list {
        color: #ffffffba !important;

        .anticon-paper-clip {
            color: #ffffffba !important;
        }
    }
}

.submitBtn{
    width: 100%;
    font-size: 0.875rem;
    background: var(--main-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0.4rem !important;

    border-radius: 0.75rem;
    -webkit-border-radius: 0.75rem;
    -moz-border-radius: 0.75rem;
    -ms-border-radius: 0.75rem;
    -o-border-radius: 0.75rem;

    &:focus{
        border: solid 3px white;
    }
}

.borderColor{
    border-color: var(--main-border-gray) !important;
}


.filterButton{
    cursor: pointer;
    color: #ffffffbf;
    background: var(--dark-blue10);
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding-top: 3px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}